<div class="main-content">
    <div class="row">
        <!-- Banner -->
        <img src="/assets/images/landing/BackgroundBanners1.webp" alt="" class="backgroundImage">
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <img src="/assets/images/landing/BackgroundBanners2.webp" alt="" class="background">
            <div class="carousel-inner">
                <!-- First Carousel -->
                <div class="carousel-item active">
                    <div class="container pt-5">
                        <div class="row pt-3 position-space">
                            <div class="col-12 pt-5 titleWhite">
                                Generación y control
                            </div>
                            <div class="col-12 titleGreen">
                                de facturas
                            </div>
                            <div class="col-12 text padding-view">
                                Genera facturas en línea cumpliendo con los
                                requisitos de la Superintendencia de
                                Administración Tributaria (SAT) además ten el
                                control de tus documentos manteniéndolos
                                resguardados y ordenados en un mismo sitio.
                            </div>
                            <div class="col 12 pt-5 firstImage">
                                <img src="/assets/images/landing/Laptop.webp" class="d-block w-80" alt="...">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Second Carousel -->
                <div class="carousel-item">
                    <div class="container pt-5 position">
                        <div class="row padding">
                            <div class="col-sm-1 col-md-4 col-lg-4 pt-3">
                                <div class="row">
                                    <div class="col-11">
                                        <img src="/assets/images/landing/Smartphone1.webp" class="d-block w-100 phone"
                                            alt="...">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-8">
                                <div class="container">
                                    <div class="row align">
                                        <div class="col-12 titleWhite">
                                            Control en la palma
                                        </div>
                                        <div class="col-12 titleGreen">
                                            de tu mano
                                        </div>
                                        <div class="col-12 text salto">
                                            Emite facturas en cualquier momento que necesites
                                            y desde la comodidad del lugar donde te
                                            encuentres, únicamente debes contar con un
                                            dispositivo con conexión a internet.
                                        </div>
                                        <div class="list col-12 pt-5">
                                            <ul class="design">
                                                <li>Totalmente responsivo.</li>
                                                <li>Mismas funcionalidades que en un
                                                    ordenador.</li>
                                                <li>Control en tiempo real.</li>
                                            </ul>
                                        </div>
                                        <div class="col-10 pt-5 imageBack">
                                            <img src="/assets/images/landing/Smartphone2.webp" class="d-block w-100"
                                                alt="...">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Third Carousel -->
                <div class="carousel-item">
                    <div class="container pt-5">
                        <div class="row pt-5 left">
                            <div class="col-sm-10 top col-md-7 col-lg-5 pt-5">
                                <div class="titleWhite">Un sistema muy</div>
                                <div class="titleGreen">fácil de usar</div>
                                <div class="text movil-view">Con I am FEL puedes emitir facturas electrónicas en
                                    línea de forma fácil, rápida y segura, no necesitas
                                    instalar ningún programa ni tener complejas
                                    capacitaciones ya que es un sistema totalmente
                                    intuitivo y adaptable a todo tipo de negocio.</div>
                            </div>
                            <div class="col-sm-4 col-md-6 col-lg-7 thirdImage">
                                <img src="/assets/images/landing/Banner3.webp" class="d-block w-75" alt="...">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="prev">
                <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span> -->
                <img src="/assets/images/landing/Flecha-I.webp" alt="">
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="next">
                <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span> -->
                <img src="/assets/images/landing/Flecha-D.webp" alt="">
                <span class="visually-hidden">Next</span>
            </button>
        </div>

        <!-- Functions -->
        <div id="funtions" class="pading">
            <app-functions></app-functions>
        </div>

        <!-- Plans -->
        <div id="plans" class="pading">
            <app-plans></app-plans>
        </div>

        <!-- Contact -->
        <div id="contact" style="margin-top: -10rem;">
            <app-contact></app-contact>
        </div>
    </div>
</div>