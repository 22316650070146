import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  functionClass = "btn-style";
  priceClass    = "btn-style";
  contactClass  = "btn-style";

  constructor() { }

  ngOnInit(){
  }

  active(section: number){
    if(section ==0){
      this.functionClass  = "btn-style";
      this.priceClass     = "btn-style";
      this.contactClass   = "btn-style";
    }else if(section == 1){
      this.functionClass  = "btn-style class-active";
      this.priceClass     = "btn-style";
      this.contactClass   = "btn-style";
    }else if(section == 2){
      this.functionClass  = "btn-style";
      this.priceClass     = "btn-style class-active";
      this.contactClass   = "btn-style";
    }else if(section == 3){
      this.functionClass  = "btn-style";
      this.priceClass     = "btn-style";
      this.contactClass   = "btn-style class-active";
    }
  }
  
  redirigir(section: number) {
    if (section == 1) {
      window.location.href = "#funtions";
    }else if (section == 2) {
      window.location.href = "#plans";
    } else if (section == 3) {
      window.location.href = "#contact";
    }
  }

  ingresar(){
    window.open('https://portal.imfel.com.gt', '_blank');
  }
}
