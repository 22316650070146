import { Component, OnInit } from '@angular/core';
import { Contact } from 'src/app/models/contact';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
 
  public contact = {} as Contact;
  public textSuccess = false;
  public textFail = false;

  constructor(
    private contactService: ContactService,
  ){

  }

  ngOnInit(){
    
  }

  async submit(){
    return this.contactService.insertContact(this.contact).then(
      response=> {
        this.textSuccess=true;
        this.clean();
      },
      error => {
        this.textFail = true;
        console.log(error);
      });
  }

  clean(){
    this.contact.name = "";
    this.contact.email = "";
    this.contact.message = "";
  }

}
