import { HttpClient, HttpHeaders} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Contact } from "../models/contact";
import { environment } from "../../environments/environment.prod";

@Injectable({
    providedIn: 'root'
})

export  class ContactService {
    public url: string;

    constructor(
        private http: HttpClient,
    ){
        this.url = environment.apiUrl;
    }

    async insertContact(contact: Contact): Promise<any>{
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(this.url + '/imfel-contact', contact, {headers: headers}).toPromise();
    }
}