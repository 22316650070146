<div class="row align">
    <div class="container pb-5">
        <div class="col-7 title">
            Útiles funciones
        </div>
        <div class="col-7 subtitle  ">
            Conoce algunas de las funciones que te ofrece I am
            FEL.
        </div>
    </div>

    <div class="col-12">
        <!-- Facturacion en linea -->
        <div class="row">
            <div class="col-6 left">
                <div class="dot">
                </div>
                <div class="container dot2 first">
                </div>
            </div>
            <div class="col-6 d-flex right">
                <img src="/assets/images/landing/BackgroundFunciones1.webp" alt="" class="backR">
                <div class="row padding">
                    <div class="col-1 space">
                        <img src="/assets/images/landing/FacturacionEnLinea.webp" alt="" class="position">
                    </div>
                    <div class="col-sm-10 col-lg-5 position">
                        <div class="title-list">Facturación en línea</div>
                        <div class="text pt-1">Este sistema te permite personalizar, emitir y
                            enviar automáticamente por correo electrónico tus
                            facturas, además de permitirte anularlas de ser
                            necesario. Todo esto mediante un procedimiento
                            fácil, rápido y seguro.</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Rapida implementacion -->
        <div class="row">
            <div class="col-6 d-flex left">
                <img src="/assets/images/landing/BackgroundFunciones2.webp" alt="" class="backL">
                <div class="row padding2" style="justify-content: end;">
                    <div class="col-1 d-sm-none d-block">
                        <img src="/assets/images/landing/RapidaImplementacion.webp" alt="" class="position">
                    </div>
                    <div class="col-sm-10 col-lg-5">
                        <div class="title-list2">Rápida implementación</div>
                        <div class="text2 pt-1">Te acompañamos en el proceso de
                            implementación, evitándote trámites molestos y
                            facilitando la migración de tu información.</div>
                    </div>
                    <div class="col-1 d-sm-block d-none space">
                        <img src="/assets/images/landing/RapidaImplementacion.webp" alt="" class="position">
                    </div>
                </div>
            </div>
            <div class="col-6 right">
                <div class="dotL">
                </div>
                <div class="container dotL2 second">
                </div>
            </div>
        </div>
        <!-- Mantenimiento de clientes y proveedores -->
        <div class="row">
            <div class="col-6 left">
                <div class="dot">
                </div>
                <div class="container dot2 first">
                </div>
            </div>
            <div class="col-6 d-flex right">
                <img src="/assets/images/landing/BackgroundFunciones1.webp" alt="" class="backR">
                <div class="row d-flex padding">
                    <div class="col-1 space">
                        <img src="/assets/images/landing/Mantenimiento.webp" alt="" class="position">
                    </div>
                    <div class="col-10">
                        <div class="row position">
                            <div class="title-list col-10">Mantenimiento de clientes y proveedores</div>
                            <div class="text pt-1 col-sm-10 col-md-6">Almacena y administra la información de contacto,
                                facturación y crédito de tus clientes y proveedores
                                en un solo lugar.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Ingreso de compras -->
        <div class="row">
            <div class="col-6 d-flex left">
                <img src="/assets/images/landing/BackgroundFunciones2.webp" alt="" class="backL">
                <div class="row padding2" style="justify-content: end;">
                    <div class="col-1 d-sm-none d-block">
                        <img src="/assets/images/landing/IngresoDeCompras.webp" alt="" class="position">
                    </div>
                    <div class="col-sm-10 col-lg-5">
                        <div class="title-list2">Ingreso de compras</div>
                        <div class="text2 pt-1">Ingresa la información de tus compras para obtener
                            un mayor control sobre los gastos que tienes,
                            además de comparar con las ventas que realices.</div>
                    </div>
                    <div class="col-1 d-sm-block d-none space">
                        <img src="/assets/images/landing/IngresoDeCompras.webp" alt="" class="position">
                    </div>
                </div>
            </div>
            <div class="col-6 right">
                <div class="dotL">
                </div>
                <div class="container dotL2 second">
                </div>
            </div>
        </div>
        <!-- Control de cuentas -->
        <div class="row">
            <div class="col-6 left">
                <div class="dot">
                </div>
                <div class="container dot2 first">
                </div>
            </div>
            <div class="col-6 right">
                <img src="/assets/images/landing/BackgroundFunciones1.webp" alt="" class="backR">
                <div class="row padding">
                    <div class="col-1 space">
                        <img src="/assets/images/landing/ControlDeCuentas.webp" alt="" class="position">
                    </div>
                    <div class="col-sm-10 col-lg-5 position">
                        <div class="title-list">Control de cuentas</div>
                        <div class="text pt-1">Simplifica el control de tu contabilidad, llevando un
                            registro de tus cuentas por cobrar y cuentas por
                            pagar pendientes. Puedes agregar pagos parciales o
                            totales.</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Resumen en tiempo real -->
        <div class="row">
            <div class="col-6 d-flex left">
                <img src="/assets/images/landing/BackgroundFunciones2.webp" alt="" class="backL">
                <div class="row padding2" style="justify-content: end;">
                    <div class="col-1 d-sm-none d-block">
                        <img src="/assets/images/landing/Resumen.webp" alt="" class="position">
                    </div>
                    <div class="col-sm-10 col-lg-5">
                        <div class="title-list2">Resumen en tiempo real</div>
                        <div class="text2 pt-1">En el tablero puedes observar el panorama general
                            de tu negocio, ya que te muestra un resumen en
                            tiempo real de las ventas y compras realizadas en el
                            mes actual. Lo cual te permitirá ahorrar tiempo y
                            evitará que tengas que hacer cuentas a mano.</div>
                    </div>
                    <div class="col-1 d-sm-block d-none space">
                        <img src="/assets/images/landing/Resumen.webp" alt="" class="position">
                    </div>
                </div>
            </div>
            <div class="col-6 right">
                <div class="dotL">
                </div>
                <div class="container dotL2 second">
                </div>
            </div>
        </div>
        <!-- Reportes -->
        <div class="row">
            <div class="col-6 left">
                <div class="dotLast">
                </div>
                <div class="container dotLast2 last">
                </div>
            </div>
            <div class="col-6 d-flex right">
                <img src="/assets/images/landing/BackgroundFunciones1.webp" alt="" class="backR">
                <div class="row padding">
                    <div class="col-1 space">
                        <img src="/assets/images/landing/Reportes.webp" alt="" class="position">
                    </div>
                    <div class="col-sm-10 col-lg-5 position">
                        <div class="title-list">Reportes</div>
                        <div class="text pt-1">Genera reportes que te ayudarán a ampliar el
                            conocimiento sobre el estado de tus ventas, pagos
                            y cobros, los cuales puedes obtener filtrando por
                            fecha, además puedes descargarlos en Excel para
                            trabajar con tu información.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>