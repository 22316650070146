<div class="row align">
    <div class="container pb-5">
        <div class="col-7 title">
            Nuestros planes
        </div>
        <div class="col-7 subtitle  ">
            Tenemos el plan perfecto para ti.
        </div>
    </div>


    <div class="row position">
        <div class="col-11">
            <div class="row">
                <!-- Basic plan -->
                <div class="col-sm-12 col-md-12 col-lg-4 side">
                    <img src="/assets/images/landing/BackgroundPrecios.webp" alt="" class="back">
                    <div class="container details pt-5">
                        <img src="/assets/images/landing/PlanBasico.webp" alt="">
                        <div class="title-plan">Básico</div>
                        <!-- <div class="center pt-3">
                            <div class="price-sign">Q</div>
                            <div class="price">000</div>
                        </div> -->
                        <div class="type">Mensual</div>
                        <div class="list col-12">
                            <ul>
                                <li class="check">Rango de usuarios<br>1 – 5</li>
                                <li class="check">Precio por usuario<br>Q50.00</li>
                                <li class="check">Cobro por implementación<br>(Pago único)
                                    <br>Q500.00</li>
                                <li class="check">Soporte de lunes a viernes de 9:00 a 16:00 horas<br>
                                    (Pago mensual)<br>
                                    Incluido</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- Standar plan -->
                <div class="col-sm-12 col-md-12 col-lg-4 middle">
                    <img src="/assets/images/landing/BackgroundPrecios.webp" alt="" class="middle-img">
                    <div class="container details2">
                        <img src="/assets/images/landing/PlanEstandar.webp" alt="">
                        <div class="title-plan">Estándar</div>
                        <!-- <div class="center pt-3">
                            <div class="price-sign">Q</div>
                            <div class="price">000</div>
                        </div> -->
                        <div class="type">Mensual</div>
                        <div class="list col-12">
                            <ul>
                                <li class="check">Rango de usuarios<br>
                                    6 – 10</li>
                                <li class="check">Precio por usuario<br>
                                    Q50.00</li>
                                <li class="check">Cobro por implementación<br>
                                    (Pago único)<br>
                                    Q800.00</li>
                                <li class="check">*Soporte de lunes a viernes de 7:00 a 19:00 horas<br>
                                    (Pago mensual)<br>
                                    Q400.00</li>
                            </ul>
                            <div class="litle">*Costo por soporte<br> recomendado, se puede<br> trabajar con el incluido.</div>
                        </div>
                    </div>
                </div>
                <!-- Business plan -->
                <div class="col-sm-12 col-md-12 col-lg-4 side top">
                    <img src="/assets/images/landing/BackgroundPrecios.webp" alt="" class="back">
                    <div class="container details">
                        <img src="/assets/images/landing/PlanEmpresarial.webp" alt="">
                        <div class="title-plan">Avanzado</div>
                        <!-- <div class="center pt-3">
                            <div class="price-sign">Q</div>
                            <div class="price">000</div>
                        </div> -->
                        <div class="type">Mensual</div>
                        <div class="list col-12">
                            <ul>
                                <li class="check">Rango de usuarios<br>
                                    &#160; &#160; &#160; &#160;11 en adelante</li>
                                <li class="check">Precio por usuario<br>
                                    Q50.00</li>
                                <li class="check">Cobro por implementación<br>
                                    (Pago único)<br>
                                    Q1,200.00</li>
                                <li class="check">*Soporte 24/7<br>
                                    (Pago mensual)<br>
                                    Q800.00</li>
                            </ul>
                            <div class="litle">*Costo por soporte<br> recomendado, se puede<br> trabajar con el incluido.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>