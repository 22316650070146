<footer class="background">
    <div class="row">
        <div class="col-sm-2 col-md-2 col-lg-4 firstImage">
            <img src="assets/images/landing/Logo2.webp" alt="">
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4 secondImage">
            <img src="assets/images/landing/ByIMA.webp" alt="">
        </div>
    </div>
    <a href="#" id="top"></a>
</footer>