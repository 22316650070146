import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { combineLatest } from 'rxjs/internal/operators';
import { FelComponent } from './pages/fel/fel.component';

const routes: Routes = [
  {
    path: '',
    children:[
      {path: '', component: FelComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
