<header class="heavisible d-lg-block d-none">
    <nav class="navbar navbar-expand-lg headerBack background-color">
        <div class="container-fluid">
            
                <a class="navbar-brand position" href="#" (click)="active(0)"><img src="/assets/images/landing/Logo1.webp" alt=""
                class="btn-logo"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            
            <div class="collapse navbar-collapse label_header fluid" id="navbarSupportedContent">
                <ul class="navbar-nav btn-padding mb-2 mb-lg-0">
                    <li class="nav-item pt-2">
                        <a [class]="functionClass" href="#funtions" (click)="active(1)">Funciones</a>
                    </li>
                    <li class="nav-item pt-2">
                        <a [class]="priceClass" href="#plans" (click)="active(2)">Precios</a>
                    </li>
                    <li class="nav-item pt-2">
                        <a [class]="contactClass" href="#contact" (click)="active(3)">Contacto</a>
                    </li>
                </ul>
                <button class="btnIngresar" (click)="ingresar()"></button>
            </div>
        </div>
    </nav>
</header>

<header class="heavisible d-lg-none d-block">
    <nav class="navbar navbar-expand-lg headerBack background-color">
        <div class="container-fluid">
            <div>
                <a class="navbar-brand position" href="#" (click)="active(0)"><img src="/assets/images/landing/Logo1.webp" alt=""
                    class="btn-logo"></a>
                    <button class="btnIngresar" (click)="ingresar()"></button>
            </div>
                
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            
            <div class="collapse navbar-collapse label_header fluid" id="navbarSupportedContent">
                <ul class="navbar-nav btn-padding mb-2 mb-lg-0">
                    <li class="nav-item pt-2">
                        <a class="btn-style" data-bs-toggle="collapse"data-bs-target="#navbarSupportedContent" (click)="redirigir(1)">Funciones</a>
                    </li>
                    <li class="nav-item pt-2">
                        <a class="btn-style" data-bs-toggle="collapse"data-bs-target="#navbarSupportedContent" (click)="redirigir(2)">Precios</a>
                    </li>
                    <li class="nav-item pt-2">
                        <a class="btn-style" data-bs-toggle="collapse"data-bs-target="#navbarSupportedContent" (click)="redirigir(3)">Contacto</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>