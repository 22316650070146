<div class="row align">
    <img src="/assets/images/landing/BackgroundContacto1.webp" class="img-back">
    <div class="background pt-5">
        <div class="container pb-5">
            <div class="col-7 title">
                Comunícate con nosotros
            </div>
            <div class="col-7 subtitle pt-3">
                Para más información, envíanos un mensaje y nos<br>
pondremos en contacto contigo lo antes posible.
            </div>
        </div>
        <div class="col-9 pt-3 movil">
            <div class="row">
                <div class="col-sm-10 col-md-8 col-lg-7">
                    <div class="box">
                        <div class="row position">
                            <div class="col-sm-12 col-md-6">
                                <div class="row form-group d-flex">
                                    <div>
                                        <label for="" class="name">Nombre</label>
                                    </div>
                                    <div class="pt-2">
                                        <input 
                                        type="text"
                                        [(ngModel)]="contact.name">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 email">
                                <div class="row form-group d-flex">
                                    <div>
                                        <label for="" class="name">Email</label>
                                    </div>
                                    <div class="pt-2">
                                        <input 
                                        type="text"
                                        [(ngModel)]="contact.email">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 pt-3">
                                <div class="row form-group d-flex">
                                    <div>
                                        <label for="" class="name">Mensaje</label>
                                    </div>
                                    <div class="pt-2">
                                        <textarea cols="25" rows="10"
                                        class="form-control"
                                        [(ngModel)]="contact.message"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 pt-2">
                               <span class="subtitle" *ngIf="textSuccess">Se envío la información correctamente, te
                                estaremos
                                contactando, gracias!</span>
                                <span class="subtitle" *ngIf="textFail">Existió un problema al enviar la información,
                                    por
                                    favor
                                    intenta de nuevo.</span>
                            </div>
                            <div class="col-12 pt-2">
                                <button class="button" (click)="submit()">Enviar Mensaje</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-10 col-md-4 col-lg-4">
                    <img src="/assets/images/landing/BackgroundContacto2.webp" alt="" class="detail w-94">
                    <div class="row center">
                        <div class="col-9">
                            <div class="container background size">
                                <div class="title-contact pt-5">Detalle de contacto</div>
                                <div class="subtitle-contact pt-3">También puedes contactarnos por los siguientes
                                    medios:</div>
                                <div class="row pt-5">
                                    <div class="icon col-2"><img src="/assets/images/landing/Mail.webp" alt="" class="w-75"></div>
                                    <div class="col-10">
                                        <div class="contact">Email</div>
                                        <div class="info">info@ima.com.gt</div>
                                    </div>
                                </div>
                                <div class="row pt-3">
                                    <div class="icon col-2"><img src="/assets/images/landing/Telefono.webp" alt="" class="w-75"></div>
                                    <div class="col-10">
                                        <div class="contact">Teléfono</div>
                                        <div class="info">+502 4863-2795</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>